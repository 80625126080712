






































import Vue from 'vue';
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { tendencyChart } from '@/api/costQuotaLibrary/detail';
import moment from 'moment';

interface SeriesType {
  name: string;
  type: string;
  data: number | null[];
}

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  DataZoomComponent,
]);

export default Vue.extend({
  name: 'priceTrendChart',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    basicInfo: Object,
  },
  watch: {
    visible(val, oldVal) {
      if (val && !oldVal) {
        this.getTendencyData();
      }
    },
  },
  computed: {
    currentTimeName(): string {
      return this.timeList[this.currentTimeIndex].name;
    },
    currentTimeValue(): number {
      return this.timeList[this.currentTimeIndex].value;
    },
  },
  data() {
    return {
      currentTimeIndex: 0,
      timeList: [
        {
          name: '近7天',
          value: 7,
        },
        {
          name: '近30天',
          value: 30,
        },
      ],
      xAxis: [] as string[],
    };
  },
  created() {
    this.generateXAxis();
  },
  methods: {
    setIndex(index) {
      this.currentTimeIndex = index;
      this.generateXAxis();
      this.getTendencyData();
    },
    generateXAxis() {
      let currentTime = new Date().getTime();
      let list: string[] = [];
      for (let day = this.currentTimeValue - 1; day >= 0; day--) {
        const time = currentTime - day * 24 * 60 * 60 * 1000;
        list.push(moment(time).format('YYYY-MM-DD'));
      }
      this.xAxis = list;
    },
    getTendencyData() {
      const { bdCode, bdName, unit, majorNbr } = this.basicInfo;
      tendencyChart({
        bdCode,
        bdName,
        unit,
        majorNbr,
        dateType: 'DAY',
        dateExpr: this.currentTimeValue,
      }).then(({ status, result }) => {
        if (status === 200 && result) {
          this.echartsInit(this.dataHandler(result));
        }
      });
    },
    dataHandler(result) {
      let dataList: SeriesType[] = [];
      let num = 1;
      for (let item in result) {
        const timeMap = new Map();
        let historyTime = '';
        const xAxis = this.xAxis;
        for (let subItem of result[item]) {
          const time = subItem.billUpdateTime;
          const key = time.split(' ')[0];
          if (!xAxis.includes(key)) historyTime = key;
          timeMap.set(key, subItem.billPrice);
        }
        const currentDataList: number | null[] = [];
        let isVal = false;
        for (let i = 0; i < xAxis.length; i++) {
          const time = xAxis[i];
          if (timeMap.has(time)) {
            const val = timeMap.get(time);
            currentDataList.push(val);
            isVal = true;
          } else {
            if (!currentDataList.length || !isVal) {
              const v = historyTime ? timeMap.get(historyTime) : null;
              currentDataList.push(v);
            } else {
              currentDataList.push(currentDataList[i - 1]);
            }
          }
        }
        let dataItem = {
          name: `方案${num}`,
          type: 'line',
          data: currentDataList,
        };
        dataList.push(dataItem);
        num++;
      }
      return dataList;
    },
    echartsInit(series) {
      const chartDom = document.getElementById('chart') as HTMLElement;
      const myChart = echarts.init(chartDom);
      const legendData = series.map((item) => item.name);
      let option;
      option = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          left: '2%',
          data: legendData,
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xAxis,
        },
        yAxis: {
          type: 'value',
        },
        dataZoom: {
          type: 'inside',
          xAxisIndex: [0],
          start: 100,
          end: 100,
          minValueSpan: 10,
          maxValueSpan: 10,
        },
        series,
      };

      option && myChart.setOption(option);
    },
    cancel() {
      this.$emit('update:visible', false);
    },
  },
});
